<template>
  <div>
    <GmapMap
      :center="center"
      :options="options"
      :zoom="10"
      id="google-map-div"
      map-style-id="roadmap"
      style="width: 100%; height: 100vh"
      ref="mapRef"
      @click="addMarker"
    >
      <GmapMarker
        v-for="(loc, index) in locations"
        :key="index"
        icon="https://b-seen-storage.s3.amazonaws.com/p_7GiBBhSppxKBymvPtYfg.png"
        :position="loc"
        @click="toggleInfoWindow(loc)"
      />
      <GmapPolyline
        v-for="key in Object.keys(polylinePaths)"
        :key="key"
        :path="polylinePaths[key]"
      />
    </GmapMap>
  </div>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}
</style>
<script>
import polylinePaths from "./location.json";
export default {
  created() {},
  components: {},
  data() {
    return {
      polylinePaths: polylinePaths,
      options: {
        backgroundColor: "#000",
        styles: [
          {
            featureType: "all",
            elementType: "geometry.fill",
            stylers: [
              {
                weight: "2.00",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#9c9c9c",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#7b7b7b",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#46bcec",
              },
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c8d7d4",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#070707",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
        ],
      },
      center: { lat: 24.7136, lng: 46.6753 },
      driver: "",
      driver_id: "",
      heatMapPoints: [],
      locations: [],
      campaign_id: "",
      isBusy: false,
      selectedInspection: undefined,
      timeoutID: undefined,
      mapLocations: [],
      playBackIndex: 0,
    };
  },
  mounted() {
    if (this.customHeight != null) {
      document.getElementById("google-map-div").style.height =
        this.customHeight + "px";
    }

    this.loadHeatLocations();
    this.moveToNextPosition();
  },
  methods: {
    toggleInfoWindow(marker) {
      console.log(marker);
      if (marker.user)
        this.$router.push({
          name: "DriverDetails",
          params: { id: marker.user },
        });
    },
    updateMilageModal() {
      this.$bvModal.show("inspection-modal");
    },
    hideActionModal() {
      this.$bvModal.hide("inspection-modal");
    },
    selectedInspectionSelected(data) {
      this.$router.push({
        name: "InspectionDetails",
        params: { id: data._id },
      });
    },
    // add lat long on click on map
    addMarker() {},
    loadHeatLocations() {
      this.heatMapPoints = [];
      for (const key in this.mapLocations) {
        if (Object.hasOwnProperty.call(this.mapLocations, key)) {
          const element = this.mapLocations[key];
          let coordinates = element.location_LngLat.coordinates;
          let lat = coordinates[1];
          let lng = coordinates[0];
          this.heatMapPoints.push({ lat: lat, lng: lng, element });

          this.heatLocations = [...this.heatLocations, { lat, lng }];
        }
      }
    },
    moveToNextPosition() {
      this.playBackIndex = this.playBackIndex + 1;

      this.locations = [];
      for (const key in polylinePaths) {
        if (Object.hasOwnProperty.call(polylinePaths, key)) {
          const element = polylinePaths[key];
          if (this.playBackIndex < element.length) {
            this.locations.push(element[this.playBackIndex]);
          }
        }
      }
      this.timeoutID = setTimeout(this.moveToNextPosition, 1000);
    },
  },
  watch: {},
  beforeDestroy() {
    if (typeof this.timeoutID === "number") {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = undefined;
  },
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}
</style>
